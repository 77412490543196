import './bootstrap';

import Alpine from 'alpinejs';
import mask from '@alpinejs/mask';

window.Alpine = Alpine;
Alpine.plugin(mask);
Alpine.start();

/**
 * Load sweetalert2 library
 */
import Swal from 'sweetalert2';
window.Swal = Swal;

import './flowbite';
